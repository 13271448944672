<template>
  <v-autocomplete
    v-model="the_value"
    :items="
      search_results.length
        ? multiple
          ? [...search_results, ...value]
          : search_results
        : multiple
          ? value
          : value
            ? [value]
            : []
    "
    :loading="isLoading"
    :label="label"
    :append-icon="isLoading ? 'mdi-loading mdi-spin' : ''"
    :search-input.sync="search"
    :placeholder="placeholder"
    :required="required"
    :disabled="disabled"
    :rules="rules"
    :outlined="outlined"
    :background-color="backgroundColor"
    :multiple="multiple"
    :chips="chips"
    :color="color"
    :hide-details="hideDetails"
    :clearable="clearable"
    :dense="dense"
    :item-text="text"
    hide-no-data
    :single-line="singleLine"
    autocomplete="false"
    no-filter
    return-object
  />
</template>

<script>
import VInput from "vuetify/lib/components/VInput/VInput.js";

export default {
  name: "InputSearch",
  extends: VInput,
  props: {
    text: {
      type: Function,
      required: true,
    },
    type: {
      type: String,
      required: true,
      enum: ["user", "location", "group"],
    },
    required: Boolean,
    outlined: Boolean,
    disabled: Boolean,
    hideDetails: [String, Boolean],
    expandeable: Boolean,
    dense: Boolean,
    label: String,
    placeholder: String,
    clearable: Boolean,
    color: String,
    backgroundColor: String,
    value: null,
    enteraction: Function,
    multiple: {
        type: Boolean,
        default: false,
    },
    singleLine: Boolean,
    chips: Boolean,
    rules: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      search: "",
      search_results: [],
      isLoading: false,
      no_watch: false,
    };
  },
  computed: {
    the_value: {
      get() {
        return this.value;
      },
      set(val) {
        this.submit(val);
      },
    },
  },
  watch: {
    search(v) {
      if (this.no_watch || (this.value && this.text(this.value) == v)) return this.no_watch=false;
      if (!this.search) {
        this.isLoading = false;
        this.search_results = [];
        if (!this.multiple) {
          this.submit(null);
        }
      } else {
        this.isLoading = true;
        this.debouncedGetSearchResults(this.search);
      }
    },
  },
  created() {
    // Une fonction "debounced" s'execute apres X ms sans avoir été appelée (pour éviter de spammer le serveur).
    // Ici 200ms
    this.debouncedGetSearchResults = this.lodash.debounce(
      this.getSearchResults,
      500
    );
  },
  methods: {
    getSearchResults(query) {
      let endpoint = {
        user: "/groups/searchUser",
        location: "/searchLocation",
        group: "/groups/searchGroup",
      }[this.type];
      this.axiosPost(process.env.VUE_APP_API_URL + endpoint, { query })
        .then((res) => {
          this.search_results = res.data;
          this.isLoading = false;
        })
        .catch((err) => {
          this.$alert.$emit("snackbar", {
            message: err.response.data,
            status: "error",
          });
        });
    },
    submit(val) {
      this.$emit("input", val);
      this.no_watch = true;
      this.search = "";
    },
    clear() {
      this.search = "";
      this.$emit("hide", false);
      document.activeElement.blur();
    },
  },
};
</script>