var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-autocomplete',{attrs:{"items":_vm.search_results.length
      ? _vm.multiple
        ? _vm.search_results.concat( _vm.value)
        : _vm.search_results
      : _vm.multiple
        ? _vm.value
        : _vm.value
          ? [_vm.value]
          : [],"loading":_vm.isLoading,"label":_vm.label,"append-icon":_vm.isLoading ? 'mdi-loading mdi-spin' : '',"search-input":_vm.search,"placeholder":_vm.placeholder,"required":_vm.required,"disabled":_vm.disabled,"rules":_vm.rules,"outlined":_vm.outlined,"background-color":_vm.backgroundColor,"multiple":_vm.multiple,"chips":_vm.chips,"color":_vm.color,"hide-details":_vm.hideDetails,"clearable":_vm.clearable,"dense":_vm.dense,"item-text":_vm.text,"hide-no-data":"","single-line":_vm.singleLine,"autocomplete":"false","no-filter":"","return-object":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},model:{value:(_vm.the_value),callback:function ($$v) {_vm.the_value=$$v},expression:"the_value"}})}
var staticRenderFns = []

export { render, staticRenderFns }