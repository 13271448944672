<template>
  <v-dialog
    v-model="dialog"
    max-width="1100"
  >
    <v-card class="pa-3">
      <v-btn
        v-if="!loading"
        dark
        icon
        color="blue"
        absolute
        top
        right
        @click="dialog = false"
      >
        <v-icon
          left
          dark
          class="mx-auto"
        >
          mdi-close-circle-outline
        </v-icon>
      </v-btn>
      <v-card-title>
        {{ group_info.name }}
      </v-card-title>
      <v-card-text v-if="!loading">
        <v-list>
          <div
            v-for="(member, index) in group_info.members"
            :key="index"
          >
            <v-divider v-if="index > 0" />
            <v-list-item>
              <v-list-item-title>
                <v-row
                  align="center"
                  class="py-2"
                >
                  <v-col cols="auto">
                    <v-chip
                      :color="member.level == 'admin' ? 'red' : 'teal'"
                      label
                      text-color="white"
                      small
                    >
                      {{
                        {
                          admin: "Admin",
                          member: "Membre",
                          observer: "Observateur",
                        }[member.level]
                      }}
                    </v-chip>
                  </v-col>
                  <v-col
                    cols="8"
                    sm="4"
                    md="auto"
                  >
                    {{ member.first_name }}
                    {{ member.last_name }} ({{ member.username }})
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                    class="ml-auto"
                  >
                    <div
                      style="display: flex; flex-direction: row; gap: 10px"
                      class="align-center"
                    >
                      <v-select
                        v-model="member.level"
                        label="Rôle"
                        outlined
                        dense
                        :items="ROLES"
                        :disabled="loading"
                        hide-details="auto"
                        @change="changeRole(member, $event)"
                      />
                      <v-btn
                        color="red"
                        outlined
                        :disabled="loading"
                        @click="deleteMember(member)"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-list-item-title>
            </v-list-item>
          </div>
        </v-list>
        <v-row align="center">
          <v-col>
            <InputSearch
              v-model="newuser"
              outlined
              label="Ajouter un membre"
              type="user"
              placeholder="Entrer un Nom / Prénom / Mail..."
              :text="text"
              clearable
            />
          </v-col>
          <v-col cols="auto">
            <v-btn
              color="green"
              :disabled="!newuser"
              @click="addMember()"
            >
              Ajouter
            </v-btn>
          </v-col>
        </v-row>
        <p class="ma-0 pt-3">
          Un <b>Observateur</b> peut regarder les évènements/FOPs mais pas les
          modifier/valider. <br> Un <b>Membre</b> peut créer/valider des
          évènements/FOPs. <br> Un <b>Admin</b> peut ajouter ou supprimer les
          individus de son groupe.
        </p>
      </v-card-text>
      <v-progress-circular
        v-else
        class="d-block mx-auto"
        indeterminate
      />
    </v-card>
  </v-dialog>
</template>

<script>
import InputSearch from "../Inputs/InputSearch.vue";

export default {
  components: { InputSearch },
  props: {
    group_id: String,
    value: Boolean,
  },
  data: () => {
    return {
      group_info: {},
      available_users: [],
      newuser: null,
      loading: false,
      // Data
      ROLES: [
        { value: "admin", text: "Admin" },
        { value: "member", text: "Membre" },
        { value: "observer", text: "Observateur" },
      ],
    };
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(x) {
        this.$emit("input", x);
      },
    },
    smallScreen() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
  },
  watch: {
    dialog(x) {
      if (x) this.update();
    },
  },
  methods: {
    addMember() {
      this.axiosPost(process.env.VUE_APP_API_URL + "/groups/addGroupMember", {
        id: this.group_id,
        username: this.newuser.username,
      })
        .then(() => {
          this.update();
        })
        .catch((err) => {
          this.$alert.$emit("snackbar", {
            message: err.response.data,
            status: "error",
          });
        });
    },
    deleteMember(member) {
      this.axiosPost(
        process.env.VUE_APP_API_URL + "/groups/deleteGroupMember",
        {
          id: this.group_id,
          username: member.username,
        }
      )
        .then(() => {
          this.update();
        })
        .catch((err) => {
          this.$alert.$emit("snackbar", {
            message: err.response.data,
            status: "error",
          });
        });
    },
    changeRole(member, current) {
      let newrole = current;
      this.loading = true;
      this.axiosPost(process.env.VUE_APP_API_URL + "/groups/updateMemberRole", {
        group: this.group_id,
        username: member.username,
        newrole,
      })
        .then(() => {
          this.update();
        })
        .catch((err) => {
          this.$alert.$emit("snackbar", {
            message: err.response.data,
            status: "error",
          });
        });
    },
    text(x) {
      if (!x.username) return "";
      return `${x.first_name || ""} ${x.last_name || ""} (${x.username})`;
    },
    update() {
      this.loading = true;
      return this.axiosPost(
        process.env.VUE_APP_API_URL + "/groups/getGroupInfo",
        {
          id: this.group_id,
        }
      )
        .then((res) => {
          this.group_info = res.data;
          this.loading = false;
        })
        .catch((err) => {
          this.$alert.$emit("snackbar", {
            message: err.response.data,
            status: "error",
          });
        });
    },
  },
};
</script>